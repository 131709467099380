.exercise-form-container {
    display: flex;
    flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--light-blue);
    padding: 0px 50px;
    border: 0.75px solid var(--soft-white);
    outline: 1px solid var(--light-blue);
    outline-offset: 5px;
    border-radius: 8px;
	/* height: 80vh; */
}

.exercise-form-container input {
    border: none;
}

.exercise-form {
	border-radius: 8px;
	padding: 0 30px;
	animation: exercise-form-border 0.8s ease-in 0s 1 normal forwards;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	font-size: 1em;
    width: 100%;
}

.exercise-entry-form {
    display: flex;
    align-items: center;
    justify-content: center;
}

.exercise-input-container  {
    display: flex;
    flex-direction: column;
}

.exercise-input-container > :nth-child(2) {
    padding-right: 60px;
}

.exercise-form > * {
	margin: 10px 0px;
	font-size: 1em;
    display: flex;
    justify-content: center;
}

.exercise-form-container > h2 {
	align-self: center;
	font-size: 3rem;
	font-weight: 700;
	letter-spacing: 2.5px;
	color: var(--form-header-color);
}

#exercise-input-span, .exercise-entry-form > span {
    margin-right: 5px;
    margin-left: 15px;
    font-weight: bold;
    color: var(--form-text-input-color);
}

.workout-form-date {
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--form-text-input-color);
}

#exercise-input-div {
    position: relative;
    margin: 15px 0px;
    display: flex;
    justify-content: center;
    min-width: 957px;
    align-items: center;
}

.exercise-input-delete-btn {
	border: 0px solid var(--form-button-color);
	background-color: var(--form-button-color);
	border-radius: 20px;
	width: 50px;
	padding: 5px;
	color: var(--form-text-input-color);
    margin-left: 10px;
}

.exercise-entry-form > input[type=textarea] {
    min-height: 50px;
    min-width: 400px;
    margin-top: 5px;
    border-radius: 8px;
    text-indent: 3px;
}

.exercise-entry-form > input[type=number] {
    height: 30px;
    width: 30px;
    margin-top: 5px;
    border-radius: 8px;
    text-indent: 3px;
}

.exercise-entry-form > input[type=file] {
    width: 207px;
}


/* .exercise-entry-form > input[type=date] {
    height: 30px;
    border-radius: 8px;
    color: gray;
    text-indent: 3px;
} */

#exercise-input-div > input[type=text] {
    height: 30px;
    min-width: 50px;
    border-radius: 8px;
    text-indent: 5px;
}

#exercise-input-div > input[type=number] {
    height: 30px;
    border-radius: 8px;
    width: 50px;
    text-indent: 3px;
}

.exercise-event-form-btn-container {
    display: flex;
    /* justify-content: space-around; */
}

.exercise-event-form-btn {
    width: 25%;
    border: 0px solid var(--form-button-color);
	background-color: var(--form-button-color);
	color: var(--form-text-input-color);
	border-radius: 20px;
	padding: 5px;
    margin: 5px 20px;
}

.exercise-input-delete-btn:hover {
    cursor: pointer;
}

.exercise-event-form-btn:hover {
    cursor: pointer;
    transition: 0.3s ease;
    transform: scale(1.01);
    box-shadow: 1px 1px 5px #05384d;
}

.workout-form-line {
    border-bottom: 1px solid var(--white);
    width: 75%;
    width: 95%;
    margin: 10px auto;
    opacity: 75%;
}

.workout-form-line#line-one {
    width: 30%
}

#exercise-form-date {
    color: var(--orange);
}

.header-quote {
    margin: 30px auto;
    font-style: italic;
    color: gray;
}

.fuzzy-dropdown {
    position: absolute;
    left: 16%;
    top: -1.7em;
    margin-top: 60px;
    margin-left: 6px;
    width: 170px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 1px;
    max-height: 11em;
    overflow-y: auto;
    z-index: 1;
}

.fuzzy-dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
}

.fuzzy-dropdown-item:hover {
    background-color: #f5f5f5;
}