/* TODO Add site wide styles */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* RESET - END */
/* RESET - END */
/* RESET - END */

body {
	/* COLORSPACE */
	--dark-blue: #024059;
	--dark-blue-lighter: #064a66;
	--medium-blue: #6BA7BE;
	--medium-blue-darker: #279bc9;
	--light-blue: #b0cdd9;
	--light-blue-darker: #b7d2de;
	--orange: #F2490C;
	--dark-red: #730202;
	--yellow: yellow;
	--soft-white: #FBFAF5;
	--white: white;
	--black: black;

	/* COLORSPACE: RATING GRADIENT */
/* 
	--rating-5: rgba(255, 255, 0, 1);
	--rating-4: rgba(255, 237, 88, 1);
	--rating-3: rgba(255, 223, 132, 1);
	--rating-2: rgba(239, 212, 169, 1);
	--rating-1: rgba(206, 205, 204, 1); */

	/* --rating-base-5: rgb(5, 194, 1);
	--rating-base-4: rgb(91, 219, 89);
	--rating-base-3: rgb(84, 137, 67);
	--rating-base-2: rgb(60, 81, 53);
	--rating-base-1: rgb(68, 68, 68); */


	/* --rating-transparency: 0.75;
	--rating-5: rgba(5, 194, 1, var(--rating-transparency));
	--rating-4: rgba(91, 219, 89, var(--rating-transparency));
	--rating-3: rgba(84, 137, 67, var(--rating-transparency));
	--rating-2: rgba(60, 81, 53, var(--rating-transparency));
	--rating-1: rgba(68, 68, 68, var(--rating-transparency)); */

	--rating-transparency: 0.75;
	--rating-1: rgba(250, 244, 244, 0.9);
	--rating-2: rgba(191, 202, 204, 0.9);
	--rating-3: rgba(109, 177, 193, 0.9);
	--rating-4: rgba(0,57,76, 0.9);
	--rating-5: rgba(1,18,46, 0.9);

	/* FONTSPACE */
	--serif: Georgia, serif;
	--sans-serif: Arial, Helvetica, sans-serif;
	--montserrat: 'Montserrat', sans-serif;
	--remu: 'Reem Kufi Ink', sans-serif;

	/* STANDARD DIVIDER BORDER STYLE */
	--dark-red-divider: 1px solid var(--dark-red);
	--white-divider: 1px solid var(--white);
	--soft-white-divider: 1px solid var(--soft-white);

	line-height: 1;
	background-color: rgb(243, 242, 239);
	background-color: rgb(239, 242, 243);

}

body {

}

ol, ul {
	list-style: none;
}

*:focus {
	outline: none;
}

a, a:visited, a:hover, a:active {
	color: inherit;
}

a.active {
	/* color: var(--orange); */
}

.app-container {
	position: relative;
}

body * {
	font-family: var(--form-font-family);
	text-decoration: none;
	font-size: 16px;
	list-style: none;
}

body input {
	font-family: var(--form-font-family);
}

h1 {
	font-size: 4rem;
	font-weight: 700;
}

h2 {
	font-size: 3rem;
	font-weight: 700;
}

h3 {
	font-size: 2rem;
	font-weight: 600;
}

h4 {
	font-size: 1.5rem;
	font-weight: 500;
}

h5, h6 {
	font-size: 1.2rem;
	font-weight: 500;
}