.landing-page-container {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	/* overflow: hidden; */
}

.landing-gif {
	width: 100%;
	height: 100%;
	object-fit: cover;
	/* object-fit:fill; */
	/* object-position: top; */
	/* object-fit:contain; */
}

.background-gif-container {
	position: relative;
	width: 100%;
	height: 100%;
	/* object-fit: cover; */
	/* display: flex; */
}

/* .blur-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,1);
	background-color: red;
} */

.text-overlay {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
}

.text-overlay p {
	/* color: rgba(255,255,255,0); */
	/* color: #e5e1e1; */
	color: white;
	opacity: 0;
	/* text-shadow: 1px 1px 2px gray; */
	font-size: 40px;
	font-family: var(--sans-serif);
}

.text-overlay > p:nth-child(1) {
	animation: white-text-fade-in 1s linear 2s 1 normal both;
}

.text-overlay > p:nth-child(2) {
	animation: white-text-fade-in 1s linear 5s 1 normal both;
}

.text-overlay > p:nth-child(3) {
	animation: white-text-fade-in 1s linear 8s 1 normal both;
}

.text-overlay p .fa-arrows-to-circle {
	font-size: 1em;
	color: var(--orange);
	/* cursor: pointer; */
}

@keyframes white-text-fade-in {
	0% {
		/* color: rgba(255,255,255,0); */
		opacity: 0;
		text-shadow: 2px 2px 2px rgba(128,128,128,0)
	}
	100% {
		opacity: 1;
		/* color: #e5e1e1; */
		/* text-shadow: 1px 1px 2px gray; */
		text-shadow: 2px 2px 2px rgba(128,128,128,1)
	}
}

.demo-login-buttons {
	margin-top: 20px;
}

.demo-login-buttons button {
	background-color: rgba(0,0,0,0);
	margin: 0px 10px;
	border: 2px solid #e5e1e1;
  color: #e5e1e1;
  padding: 14px 28px;
  font-size: 18px;
  cursor: pointer;
	font-family: var(--sans-serif);
}

.demo-login-buttons button:nth-child(1) {
	/* color: blue; */
}
.demo-login-buttons button:nth-child(2) {
	/* color: yellow; */
}
.demo-login-buttons button:nth-child(3) {
	/* color: green; */
}
.demo-login-buttons button:nth-child(4) {
	/* color: red; */
}

.demo-login-buttons button:hover {
	/* border-color: black; */
	background-color: rgba(242, 73, 12, 0.6);
	color:black;
	transition: 0.5s;
}

p {
	cursor: default;
}