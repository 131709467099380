.exercise-outer-container {
	position: relative;
	--tile-side-length: 80px;
	transform: scale(1);
	/* transition: 0.3s; */
	animation: reverseTileContainerHover 0.3s linear 0s 1 normal forwards;

}

.exercise-entry-tile-container {
	position: relative;
	box-sizing: border-box;
	width: var(--tile-side-length);
	height:var(--tile-side-length);
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.tile-background-1 {
	color: var(--black);
	/* color: blue; */
}

.tile-background-2 {
	color: var(--black);
	/* color: blue; */
}

.tile-background-3 {
	color: var(--black);
	/* color: blue; */
}

.tile-background-4 {
	color: var(--white);
	/* color: blue; */
}

.tile-background-5 {
	color: var(--white);
	color:white;
	/* color: blue; */
}

.tile-photo {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transform: scale(1);
	transition: all 0.5s ease;
	animation: reverseTileImgHover 0.5s linear 0s 1 reverse forwards;
}

.tile-placeholder {
	display: flex;
	justify-content: center;
	align-items: center;
}

.tile-placeholder > i {
	font-size: 1.7em;
}

.exercise-outer-container:hover {
	/* transform: scale(0.90); */
}

.exercise-outer-container:hover .tile-photo {
	/* transform: scale(1.5); */
}

.tile-rating-overlay {
	position: absolute;
	top: 0px;
	width: var(--tile-side-length);
	height: var(--tile-side-length);
	height: 100%;
	transition: background-color 0.5s, color 1s;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	justify-content: space-between;
}

.tile-date-text {
	color: var(--dark-blue);
	font-size: 16px;
	padding: 1px;
	padding-right: 3px;
	display: flex;
	flex-direction: column;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-end;
}

.tile-date-text > span:not(:first-child)::before {
	content: "-";
}

.tile-date-text span {
	font-family: var(--sans-serif);
	font-size: 0.9em;
	color: var(--white);
}

.tile-rating-5 span {
	color: var(--medium-blue);
}

.tile-date-text span:nth-child(3) {
	color: var(--medium-blue);
	font-weight: 400;
}

.tile-rating-1 span:nth-child(1), .tile-rating-1 span:nth-child(2) {
	color:var(--black);
}

.tile-rating-3 span:nth-child(3) {
	color:var(--dark-blue);
}

.tile-rating-5 span:nth-child(3) {
	color:var(--white);
}

.exercise-outer-container:hover .tile-rating-overlay {
	/* background-color: rgba(0,0,0,0); */
}

.tile-active-overlay {
	background-color: rgba(0,0,0,0) !important;
}

.exercise-outer-container:hover .tile-date-text {
	color: black;
}

.tile-rating-1 {
		background-color: var(--rating-1);
}
.tile-rating-2 {
		background-color: var(--rating-2);
}
.tile-rating-3 {
		background-color: var(--rating-3);
}
.tile-rating-4 {
		background-color: var(--rating-4);
}
.tile-rating-5 {
		background-color: var(--rating-5);
}

.tile-container-hover {
	animation: tileContainerHover 0.3s linear 0s 1 normal forwards;
}

.tile-img-hover {
	animation: tileImgHover 0.5s linear 0s 1 normal forwards;
}

.rev-tile-container-hover {
	animation: reverseTileContainerHover 0.3s linear 0s 1 normal forwards;
}

.rev-tile-img-hover {
	transform: scale(1);
	transition: all 0.5s ease;
}

@keyframes tileContainerHover {
	0% {
		transform: scale(1);
	}
	100%{
		transform: scale(0.75);
	}
}

@keyframes tileImgHover {
	0% {
		transform: scale(1);
		color: rgb(48, 48, 48);
	}
	100%{
		transform: scale(1.8);
		color: rgb(48, 48, 48);
	}
}
@keyframes reverseTileContainerHover {
	0% {
		transform: scale(0.75);
	}
	100%{
		transform: scale(1);
	}
}

@keyframes reverseTileImgHover {
	0% {
		transform: scale(1.8);
	}
	100%{
		transform: scale(1);
	}
}

.rating-clearly {
	justify-self: flex-end;
	padding-left: 2px;
	padding-bottom: 2px;
	font-size: 0.75em;
}

.rating-clearly-1, .rating-clearly-2 {
	color: black;
}

.rating-clearly-3, .rating-clearly-4 {
	color: white;
}

.rating-clearly-5 {
	color: var(--orange);
	animation: onFireText 3s ease-in-out 0s infinite normal both;
}

@keyframes onFireText {
	0% {
		color: rgb(244, 244, 130);
		/* transform: rotateY(0deg) rotateX(0deg) scale(3) skewX(40deg); */
		/* transform-origin: center; */
		/* transform-origin: bottom right; */
	}
	20% {
		color: rgb(194, 169, 96);
	}
	30% {
		color: var(--orange);
		/* transform: rotateY(0deg) rotateX(180deg) scale(3); */
		/* transform-origin: center; */
		/* transform-origin: bottom right; */
	}
	44% {
		color: gray;
		/* transform: rotateY(0deg) rotateX(0deg) scale(3) skewX(40deg); */
		/* transform-origin: center; */
		/* transform-origin: bottom right; */
	}
	65% {
		color: var(--orange);
	}
	75% {
		color: red;
	}
	85% {
		color: red;
	}
	89% {
		color: red;
	}
	100% {
		color: rgb(244, 244, 130);
	}
}