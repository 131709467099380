/* body {
	--form-background-color: var(--medium-blue);
	--form-button-color: var(--dark-blue);
	--form-errors-color: var(--yellow);
	--form-text-input-color: var(--white);
	--form-header-color: var(--dark-blue);
	--form-font-family: var(--serif);
} */

body {
	--form-background-color: rgb(28, 27, 27);
	--form-button-color: var(--orange);
	--form-errors-color: var(--yellow);
	--form-text-input-color: var(--white);
	--form-header-color: var(--orange);
	--form-font-family: var(--sans-serif);
}

.session-form-container {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--form-background-color);
	margin-top: 190px;
}

.session-form-container h2 {
	font-family: var(--montserrat);
}

.session-form-container + * {
	background-color: var(--form-background-color);
	
}

.session-form {
	border-radius: 8px;
	padding: 30px;
	animation: session-form-border 0.8s ease-in 0s 1 normal forwards;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	font-size: 1em;
}

.session-form > * {
	margin: 15px 0px;
	font-size: 1em;
}

.session-form > h2 {
	align-self: center;
	font-size: 3rem;
	font-weight: 700;
	letter-spacing: 2.5px;
	color: var(--form-header-color);
}

.text-input-container {
	font-size: 1em;
}

/* INPUT FONT SIZES */
.text-input-container > * {
	font-size: 1em;
}

.session-form .errors {
	font-style: italic;
	margin-top: 0.5em;
	color: var(--form-errors-color);
	font-size: 0.875em;
	height: 0.875em;
	width: 400px;
	display: flex;
	align-items: center;
}

.text-input {
	border: none;
	border-bottom: 2px solid var(--light-blue);
	width: 100%;
	background-color: var(--form-background-color);
	color: var(--form-text-input-color);
	font-family: var(--form-font-family);
}

.text-input::placeholder {
	color: var(--form-text-input-color);
}

.session-form-submit {
	align-self: center;
	border: 0px solid var(--form-button-color);
	border-radius: 20px;
	background-color: var(--form-button-color);
	width: 100px;
	padding: 10px;
	color: var(--form-text-input-color);
	font-family: var(--form-font-family);
	letter-spacing: 1px;
}

.session-form-submit:hover {
	cursor: pointer;
	background-color: var(--form-button-color);
	border-color: var(--form-button-color);
	/* animation: session-form-submit-hover 1s ease-in-out 0.5s 1 forwards forwards; */
}

.login-buttons {
	display: flex;
	justify-content: center;
}

.login-buttons > button:not(:first-child) {
	margin-left: 10px;
}

/* @keyframes session-form-submit-hover {
	0% {
		background-color: green;
	}
	50% {
		background-color: blue;
	}
	100% {
		background-color: black;
	}
} */

@keyframes session-form-border {
	0% {
		/* border-radius: 8px; */
		color: rgba(0,0,0,0.0);
		/* font-size: 35px; */
	}
	100% {
		/* border-radius: 25px; */
		color: rgba(0,0,0,1);
		/* font-size: 350px; */
	}
}