.empty-feed-container {
	margin-top: 4vw;
	margin-left: 5vw;
}

.empty-feed-container div {
	font-style: italic;
}

.feed-header {
	position: relative;
	left: 50%;
	transform: translateX(-50%);

	align-self: flex-start;
	padding-top: 30px;
	padding-bottom: 20px;
	padding-left: 90px;
	margin: 30px 0px;
	font-family: var(--montserrat);
	font-weight: 500;
	white-space:nowrap;
	min-width: 1330px;
	width: 1330px;
	/* margin: 0px auto; */
	box-sizing: border-box;
}

.feed-posts-container {
	/* min-width: 600px; */
	min-width: 1330px;
	width: 1330px;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-direction: row;
	justify-content: center;
	/* justify-content: space-between; */
	align-items: flex-start;
	/* flex-grow: 6; */
	/* height: 100vh; */
	/* top:  */
	/* overflow-x: scroll; */
	/* overflow-y: scroll; */
	/* overflow: scroll; */
	/* padding-left: 40px; */
	/* padding-right: 80px; */
	/* padding: 0vw 10vw; */
	/* width: 90vw; */
	/* padding-right: 5vw; */
}



.inner-feed-posts-container {
	min-width: 600px;
	margin-top: 6px;
	width: 60%;
	justify-self: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* align-items: flex-start; */

	/* CENTER ENTIRE FEED */
	align-items: center;
	margin-bottom: 80px;
	/* opacity: 0; */
	/* animation: mainFadeAnimation 01s ease 0.2s 1 normal forwards; */
	/* perspective: 4500px; */
}

.inner-feed-posts-container > h2 {
	/* position: absolute; */
	/* top: 200px; */
	/* margin: 20px 0px; */
	/* z-index: -1; */
}

#toggle1 {
	display:none;
}


#toggle2 {
	display: none;
}

.feed-header-letter {
	font-size: 3rem;
	font-weight: 700;
	margin: 20px 0px;
	font-family: var(--montserrat);
	font-weight: 500;
	opacity: 0;
	animation: fadeInLetter 0.18s ease-in-out 0s 1 normal forwards;
}

.feed-profile-picture {
	height: 40px;
	/* height: 100%; */
	width: 40px;
	/* width: auto; */
	/* border-radius: 20px; */
	border-radius: 50%;
	margin-right: 20px;
	object-fit: cover;
}

.post-photo-container {
	display: flex;
	width: 100%;
	height: 100%;
	/* clip-path: inset(10px 20px 30px 40px); */
	/* clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%); */
	
}

.feed-goal-picture {
	height: 100%;
	width: 100%;
	object-fit: cover;
	border-radius: 20px;
	margin: 10px 0;
}

.feed-workout-picture {
	height: 100%;
	width: 100%;
	object-fit: cover;
	margin: 20px 0;
	border-radius: 20px;
}

@keyframes fadeInLetter {
	0% {
		opacity: 0;
		/* transform: rotateY(0); */
		color: white;
	}
	40%{
		color: white;
	}
	50% {
		opacity: 1;
		color: orange;
		/* transform: rotateY(90deg); */

	}
	100% {
		opacity: 1;
		color: black;
		transform: scale(-1,1);
		/* transform: rotateY(180deg); */
	}
}