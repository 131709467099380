.create-feed-post > input[type=textarea] {
  min-height: 50px;
  min-width: 393px;
  margin-top: 5px;
  border-radius: 8px;
  text-indent: 5px;
}

.feed-post-preview {
  min-height: 150px;
}

.create-feed-post-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--light-blue);
  padding: 0px 50px;
  border: 0.75px solid var(--soft-white);
  outline: 1px solid var(--light-blue);
  outline-offset: 5px;
  border-radius: 8px;
  /* height: 80vh; */
}

.create-feed-post {
  display: flex;
  flex-direction: column;
  padding: 50px;

}

.create-feed-post > span {
  color: var(--form-text-input-color);
  margin-bottom: 2px;
  margin-left: 2px;
}

.feed-post-form-text, .feed-post-form-date {
  margin-bottom: 20px;
  text-indent: 3px;
}

.feed-post-form-date {
  border-radius: 8px;
  margin-top: 5px;
  color: gray;
}

.create-feed-post-submit {
  align-self: center;
	border: 0px solid var(--form-button-color);
	border-radius: 20px;
	background-color: var(--form-button-color);
	width: 100px;
	padding: 10px;
	color: var(--form-text-input-color);
	font-family: var(--form-font-family);
	letter-spacing: 1px;
}

.create-feed-post-submit:hover {
  cursor: pointer;
  transition: 0.3s ease;
  transform: scale(1.01);
  box-shadow: 1px 1px 5px #05384d;
}

.goal-create-step-inputs {
  display: flex;
  flex-direction: column;
}

.create-feed-post-container input {
  border: none;
}