canvas {
    width: 100%;
    height: 500px; 
}

.data-vis {
    padding: 60px;
    padding-top: 20px;
}

.progress-title {
    padding: 20px;
}

.loading {
    width: 100%;
    height: 100%;
}