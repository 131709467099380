.grid-item-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: flex-start;
    background: var(--white);
    box-shadow: 5px 10px 10px 1px #c8d6dd;
}

#ellipsis {
    color: var(--dark-blue);
    padding: 5px;
    font-size: 20px;
    transition: all 0.5s;
}

#ellipsis:hover {
    cursor: pointer;
    border-radius: 50%;
    border-radius: 10px;
	background-color: rgb(223, 222, 219);
}

.goal-dropdown {
    position: absolute;
    z-index: 1;
    background-color: var(--light-blue);
    border: 1px solid var(--light-blue);
    top: 35px;
    right: 14px;
    opacity: 95%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.2);
    min-width: 35px;
}

#goal-dropdown-line {
    border-bottom: 1px solid var(--medium-blue);
    width: 50%;
    margin: 10px auto;
} 

.goal-dropdown li {
    display: flex;
    justify-content: center;
    padding: 4px;
    font-size: 15px;
    margin: 3px 0;
}

.goal-dropdown li:hover {
    cursor: pointer;
    color: var(--medium-blue-darker);
}

#previous-goal > p {
    word-break: break-word;
}

.goal-page-picture {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
	width: 100%;
	object-fit: contain;
	margin: 10px 0;
    padding: 0 5px;
}

.grid-item-previous {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.complete-date {
    align-self: flex-end;
    font-style: italic;
}

.feed-post-content > label > input {
    margin-bottom: 15px;
}

.feed-post-content > label > textarea {
    margin-bottom: 15px;
}