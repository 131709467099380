
body {
	--nav-bar-background-color: var(--light-blue);
}

.links-menu li * {
	margin: 0px 8px;
	cursor: pointer;
	font-family: var(--sans-serif);
}

.nav-links {
	display: flex;
	flex-direction: row;
}

.nav-links > li:not(:first-child)::before {
	content: "·";
}

.nav-bar-container {
	/* backdrop-filter: invert(80%); */
	/* backdrop-filter: blur(20px); */
	/* backdrop-filter: brightness(20%) invert(80%); */
	/* backdrop-filter: hue-rotate(360deg) blur(5px); */
	/* background-color: rgba(255,255,255,0.8); */
	background-color: white;
	min-height: 0px;
	position: fixed;
	top: 0px;
	z-index: 10;
	width: 100%;
	/* padding: 20px 0; */
	/* padding-bottom: 20px; */
	box-sizing: border-box;
	border-bottom: 0.5px solid lightgray;
	display: flex;
	align-items: center;
	justify-content: center;
}

.nav-bar-container-feed {
	/* backdrop-filter: hue-rotate(360deg) blur(4px); */
	/* background-color: rgba(255,255,255,0.9); */
	backdrop-filter: hue-rotate(360deg) blur(10px);
	background-color: rgba(255,255,255,0.85);
}

.nav-bar-main {
	top: 0px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.test-button {
	font-family: var(--sans-serif);
	cursor: pointer;
}

.nav-bar-logo {
	width: 100%;
	height: 100%;
	object-fit: scale-down;
}

.text-logo > i {
	font-size: 0.5em;
	color: var(--orange);
	align-self: flex-end;
	position: relative;
	bottom: 0.29em;
	padding: 0em 0.05em;
}

.text-logo {
	font-size: 7rem;
	font-family: var(--montserrat);
	padding-bottom: 10px;
	box-sizing: border-box;
	font-weight: 200;
	white-space: nowrap;
	display: flex;
	justify-content: center;
	align-items: center;

	
}

.nav-bar-offset {
	height: 190px;
}

.nav-bar-divider {
	/* min-width: 600px; */
	width: 60%;
	height: 1px;
	/* border-bottom: 1px solid var(--dark-red); */
	/* background-color: white; */
	background-color: var(--dark-red);
	/* margin: 0 0 8px 0; */
}

.under-construction {
	color: rgba(255,255,255,0.3);
}

.nav-link-id {
	transition: 0.15s ease-in;
	
}

.nav-link-id:hover {
	color: var(--orange);
	/* animation: navLinkColor 0.8s ease 0s 1 normal forwards;  */
}

@keyframes navLinkColor {
	0% {
		color: var(--black)
	}
	100% {
		color: var(--orange)
	}
	
}

.logged-in-navbar .logged-in-logo {
	font-size: 3rem;
	transition: font-size 0.7s ease 0.2s;	
}

.logged-in-logo {
}

.logged-in-divider {
	min-width: 600px;
	width: 40vw;
	width: 600px;
	transition: width 0.3s ease 0.7s;	
}

.scrolled-navbar {
	width: 600px !important;
}

/* user dropdown */
/* user dropdown */

.nav-bar-aside {
	position: absolute;
	right: 20px;
}

.nav-bar-aside a {
	cursor: pointer;
	display: flex;
	align-items: center;
}

.nav-bar-profile-picture {
	height: 60px;
	width: 60px;
	border-radius: 50%;
	cursor: pointer;
	object-fit: cover;
}

.profile-dropdown {
	position: absolute;
    z-index: 1;
    background-color: var(--light-blue);
    border: 1px solid var(--light-blue);
    top: 91px;
    right: 10px;
    opacity: 95%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.2);
	padding: 10px;
	border-radius: 8px;
}

.profile-dropdown li {
    display: flex;
		flex-direction: column;
    justify-content: flex-start;
    padding: 5px;
    font-size: 15px;
    margin: 3px 0;
}

#profile-dropdown-icon {
	margin-right: 10px;
	cursor: pointer;
}

a {
	cursor: pointer;
}

span {
	cursor: default;
}

.welcome *{
	text-align: center;
}

#greetings {
	padding-bottom: 5px;
}

.welcome > span {
	font-weight: bold;
	color: magenta;
}

.profile-dropdown-line {
	border-bottom: 1px solid var(--medium-blue);
    width: 50%;
    margin: 10px auto;
}

.profile-dropdown > li > a > i {
	width: 1em;
}

.upper-nav-bar-padding{
	height: 20px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.upper-nav-bar-padding :nth-child(1){
 	width: calc((100% - 600px) / 2);
	/* background-color: white; */
}

.upper-nav-bar-padding :nth-child(2){
	width: 600px;
	/* backdrop-filter: hue-rotate(360deg) blur(5px); */
	/* background-color: rgba(255,255,255,0.8); */
	/* background-color: white; */
}

.upper-nav-bar-padding :nth-child(3){
	width: calc((100% - 600px) / 2);
	/* background-color: white; */
}

.upper-nav-bar {
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: row;
	justify-content: center;
}

.lower-nav-bar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 8px;
	padding-bottom: 20px;
	width: 100%;
	height: 100%;
	/* background-color: white; */
}

.navbar-divider-container {
	width: 100%;
	height: 1px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	/* background-color: white; */
}

.upper-nav-filler {
	min-width: calc((100% - 600px) / 2);
	min-height: 100%;
	/* background-color: white; */
	flex-grow: 1;
}

.upper-nav-logo-container {
	width: 600px;
	/* backdrop-filter: hue-rotate(360deg) blur(5px); */
	/* background-color: rgba(255,255,255,0.8); */
	/* background-color: white; */
}

.fa-play {
	transition: all 0.2s ease-out;
	cursor: pointer;
}

.fa-play#dropdown-arrow-down {
	transform: rotate(90deg);
}