.goals-container {
    padding: 30px 90px;
}

.goals-container > h2 {
    /* margin-top: 20px; */
    margin-bottom: 20px;
    color: var(--dark-blue);
    /* color: var(--black); */
    text-shadow: 1px 1px 4px var(--light-blue);
    font-family: var(--montserrat);

    font-size: 3rem;
	font-family: var(--montserrat);
	font-weight: 500;
}

#current-goal {
    outline: 2px solid var(--light-blue);
    outline-offset: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--white);
}

.goals-grid-container {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: auto;
    row-gap: 10px;
    column-gap: 10px;
}

.grid-item-container, .grid-item {
    border: 1px solid var(--medium-blue);
    padding: 10px;
    border-radius: 5px;
}

.grid-item-previous > p {
    word-break: break-all;
}

.grid-item > div > p {
    font-family: var(--sans-serif);
}

div > p {
    margin: 15px 0;
    color: var(--dark-blue);

}

#current-goal {
    grid-column: 1 / span 3;
}

#previous-goal {
    grid-column: span 1;
}

.goal-crud {
    display: flex;
    align-items: center;
}

.edit-current-goal, .delete-current-goal {
    margin: 5px;
    padding: 10px;
    border-radius: 8px;
    background-color: var(--dark-red);
    border: 1px solid var(--dark-red);
    color: var(--white);
    max-width: 16px;
}

.create-current-goal {
    margin: 5px;
    padding: 10px;
    border-radius: 8px;
    background-color: var(--dark-red);
    border: 1px solid var(--dark-red);
    color: var(--white);
}

.edit-current-goal:hover, .delete-current-goal:hover, .create-current-goal:hover {
    cursor: pointer;
    transition: 0.3s ease;
    transform: scale(1.01);
    box-shadow: 1px 1px 5px #05384d;
}

.goal-title {
    font-weight: bold;
    color: var(--medium-blue-darker);
    font-family: var(--sans-serif);
}

#x {
    font-size: 16px;
    font-family: "Font Awesome 6 Free"
}

.current-goal-edit-crud-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.current-goal-edit-crud-button {
    cursor: pointer;
    background-color: var(--form-button-color);
    color: var(--form-text-input-color);
    padding: 8px;
    margin: 5px;
    font-family: var(--sans-serif);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-radius: 8px;
}

.current-goal-edit-crud-button#close-edit-current-goal {
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
}

#previous-goal-title {
    margin-top: 50px;
}

.status {
    font-style: italic;
}