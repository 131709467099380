.profile-container {
	/* position: absolute; */
	padding-top: 30px;
	padding-left: 90px;
	padding-right: 90px;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	/* background-color: orange; */
	/* background-color: lightgray; */
}

.profile-container * {
	box-sizing: border-box;
}

.profile-container > h3 {
	font-size: 3rem;
	font-family: var(--montserrat);
	font-weight: 500;
}

.profile-container-styles {
	/* background-color: yellowgreen; */
	/* border: 0.5px dashed var(--orange); */
	box-sizing: border-box;
	padding: 5px;
	margin: 5px;
	align-self: center;
	width: 90%;
}

.empty-calendar-placeholder {
	font-size: 2em;
	font-style: italic;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 40px;
}

.workout-log-bg-photo-box {
	position: relative;
	top: 0px;
	right: 0px;
	min-height: 100%;
	/* display: flex; */
	/* max-height: 100%; */
	/* min-width: 1px; */
	z-index: -1;
	
}


.workout-log-bg-photo {
	position: absolute;
	right: -4px;
	top: -4px;
	/* height: 100%; */
	height: 200px;
	/* width: 100%; */
	object-fit: cover;
	/* z-index: -100; */
	z-index: -2;
}


.workout-log-bg-overlay {
	position: absolute;
	width: 100%;
	min-height: 100%;
	min-height: 200px;
	background-color: rgba(0,0,0,0);
	right: -4px;
	top: -4px;
	z-index: 1;
}

.profile-workout-calendar-container {
	position: relative;
	width: 90%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.profile-goal-workout-content-container {
	min-height: 200px;
	position: relative;
	display: flex;
	/* align-items: stretch; */
	color: red;

}

.workout-component {
	/* background-color: azure; */
	/* margin: 20px; */
	/* min-width: 50px; */
	/* min-height: 50px; */
}

.profile-workout-selector-container {
	/* min-height:700px; */
	flex-grow: 2;
	/* width: 75%; */
	flex-basis: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: flex-start;
	flex-wrap: wrap;
	width: 720px;
	/* background-color: white; */
	
}

.profile-exercise-chart {
	position: sticky;
	top: 200px;

	/* STICKY CAN TAKE A MIN HEIGHT */
	min-height: 200px;
	/* THIS ALLOWS STICKY TO EXPAND WITH CONTENTS WHILE STILL EXHIBITING "STICKY" BEHAVIOR */
	height: 100%;
	flex-grow: 1;
	flex-basis: 0;
	/* background-color: blanchedalmond; */
	min-width: 300px;

	display: flex;
	flex-direction: column;
	padding-left:10px;
}

.profile-exercise-chart * {
	font-family: var(--sans-serif);
	margin-bottom: 5px;
	color: var(--dark-blue);
	/* color: var(--white); */
	/* text-shadow: black 1px 0 10px; */
}

.exercise-entry-deets {
	margin-bottom: 10px;
}

.exercise-entry-deets-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	min-height: 0px;
}

.exercise-entry-deets-header * {
	color: var(--medium-blue-darker);
	/* color: var(--orange); */
	/* text-shadow: 0px 0px 10px white; */

	font-weight: 600;
}

.exercise-entry-deets-note {
	font-style: italic;
}

.chart-div {
	border-bottom: var(--soft-white-divider);
	margin-bottom: 10px;
}

.exercise-chart-table tr {
	padding: 3px 0px;
}

.exercise-chart-header-row * {
	color: var(--medium-blue-darker);
	/* color: var(--orange); */
	/* text-shadow: 0px 0px 10px white; */
	font-weight: 600;
	padding-bottom: 30px;
}

.exercise-chart-table th,td {
	text-align: left;
	padding: 0.5px 0px;
	/* margin: 5px 0px; */
}

tfoot * {
	font-style: italic;
}

.profile-footer {
	height: 1000px;
}

.profile-submit {
	width: fit-content;
}

.profile-container-styles {
	padding-top: 60px;
}

/* datavis area CSS */
/* datavis area CSS */

#progress-goal-title {
	margin: 20px 0;
	font-family: var(--montserrat);
	font-family: var(--sans-serif);
	font-weight: bold;
	letter-spacing: 1px;
	font-size: 38px;
	color: var(--orange);
	color: black;

	/* font-size: 3rem; */
	/* font-family: var(--montserrat); */
	/* font-weight: 500; */
}

.progress-header {
	display: flex;
	align-items: center;
	flex-direction: column;
}
.progress-header *{
	cursor: default;
}

.progress-header *{
	margin-bottom: 5px;
}

#progress-subheader {
	letter-spacing: -0.5px;
	font-size: 22px;
	
	font-size: 3rem;
	font-family: var(--montserrat);
	font-weight: 500;
	align-self: flex-start;
}

.profile-line {
	margin-top: 5px;
	justify-self: flex-start;
	border-bottom: 1px dotted var(--orange);
    width: 50%;
    /* margin: 10px auto; */
}

/* slider CSS */
/* slider CSS */

.toggle-container {
	display: flex;
	justify-content: flex-end; 
	padding-right: 60px;
}

.toggle-graph {
    --width: 110px;
    --height: calc(var(--width) / 3);
    position: relative;
    display: inline-block;
    width: var(--width);
    height: var(--height);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: var(--height);
    cursor: pointer;
}

.toggle-graph input {
	display: none;
}

.toggle-graph .slider-graph {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--height);
    background-color: var(--dark-blue);
    transition: all 0.4s ease-in-out;
}

.toggle-graph .slider-graph::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(var(--height));
    height: calc(var(--height));
    border-radius: calc(var(--height) / 2);
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    transition: all 0.4s ease-in-out;
}

.toggle-graph input:checked + .slider-graph {
    background-color: var(--medium-blue-darker);;
}

.toggle-graph input:checked + .slider-graph::before {
    transform: translateX(calc(var(--width) - var(--height)));
}

.toggle-graph .slider-labels {
	position: absolute;
	top: 8px;
	left: 0;
	width: 100%;
	height: 100%;
	font-size: 12px;
	font-family: var(--sans-serif);
	transition: all 0.3s ease-in-out;
	margin-top: 6px;
}

.toggle-graph .slider-labels::after {
	content: attr(data-off);
	position: absolute;
	right: 5px;
	color: #ffffff;
	opacity: 1;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
	transition: all 0.3s ease-in-out;
	margin: 0px 20px;
}

.toggle-graph .slider-labels::before {
	content: attr(data-on);
	position: absolute;
	left: 5px;
	color: #ffffff;
	opacity: 0;
	text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
	transition: all 0.4s ease-in-out;
}

.toggle-graph input:checked~.slider-labels::after {
	opacity: 0;
}

.toggle-graph input:checked~.slider-labels::before {
	opacity: 1;
}

.update-profile-picture {
	padding-bottom: 40px;
}