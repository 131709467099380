p, h3 {
  margin-left: 5px;
}


.feed-post-editable-container {
	border-radius: 10px;
	margin-bottom: 5px;
	min-height: 57px;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	/* justify-content: space-between; */
	justify-content: stretch;
	/* min-width: 500px; */
	/* width: 40vw; */
	margin: 15px;
	/* margin-left: 10vw; */
	/* margin-left: 55px; */
	border-right: none;
	border-bottom: none;
	border: none;
	transition: background-color 0.5s;
	background-color: var(--white);
}

.feed-post-editable-container {
	box-shadow: 5px 10px 10px 1px #c8d6dd;
}

.feed-post-editable-container:nth-child(1) {
	margin-top: 0px;
}


.feed-post-editable-container * {
	font-size: 15px;
	
}

.feed-post-content {
	display: flex;
	flex-direction: column;
	width: 600px;
	word-wrap: break-word;
	box-sizing: border-box;
	margin: 20px;
}

.feed-post-content *:not(.fa-solid):not(.fa-edit) {
	font-family: var(--sans-serif);
}

.feed-post-row {
	display: inline;
	display: block;
	line-height: 1.2;
}

.feed-post-header {
	height: 40px;
	display: flex;
	flex-direction: row;
	/* column-gap: 5px; */
	position: relative;
}

.feed-goal-text-box {
	display: flex;
	flex-direction: column;

}

.feed-post-header * {
	/* padding: 1px; */
	/* align-self: center; */
}

.feed-post-header-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

.feed-post-header-text-row {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	column-gap: 0.25em;
	position: relative;
}

.feed-post-header-text-row > * {
	/* padding: 0px 2px; */
}

.feed-post-row span {
	margin-right: 5px;
}

.post-username {
	/* align-self: center; */
	color: var(--medium-blue-darker);
	font-weight: 800;
}

.display-session-username {
	color: magenta;
}

.post-follow {
	position: relative;
	padding: 2px;
	bottom: 2px;
	font-weight: 600;
}

.following {
	color: var(--dark-blue);
}

.not-following {
	/* color:deeppink; */
	color:var(--orange);
}

.following:hover {
	cursor: pointer;
	background: var(--dark-blue);
	border-radius: 2px;
	/* padding:5px; */
	color: white;
	transition: color 0.3s ease 0s, background-color 0.3s ease 0s;
}

.not-following:hover {
	cursor: pointer;
	/* background:deeppink; */
	background:var(--orange);
	border-radius: 2px;
	/* padding:5px; */
	color: white;
	transition: color 0.3s ease 0s, background-color 0.3s ease 0s;
}

.post-timestamp {
	/* align-self: center; */
	color: var(--dark-red);
	font-weight: 400;
	font-size: 14px;
}

.feed-post-goal-target {
	font-style: italic;
	/* font-weight: 600; */
	/* color: var(--medium-blue-darker); */
	/* color: var(--medium-blue); */
	color: var(--dark-blue);
	/* color: rgb(93, 93, 93); */
	font-size: 0.85em;
}

/* TOP ROW (title, rating, etc) AFTER OVERALL HEADER (user, timestamp) */
.post-toprow {
	position: relative;
	margin-bottom: 10px;;
}

.post-title {
	color: var(--dark-blue);
	font-weight: 600;
}

.post-goal-title {
	/* width: 100%; */
	/* align-self: flex-end; */
	position: relative;
	color: rgb(165, 165, 165);
	font-size: 2em;
	right: 0px;
	/* top: -1em; */
	/* font-style: italic; */
	margin-bottom: 3px;
	/* border-bottom: 1px solid black; */
	/* z-index: 1; */
}

.post-goal-description {
 /* margin-left: 10px; */
 /* font-style: italic; */
}

.post-form {
	/* display: flex; */
	/* flex-direction: column; */
	/* justify-content: flex-start; */
	/* align-items: stretch; */
}

.feed-post-text-edit {
	box-sizing: border-box;
	word-wrap: break-word;
	width: 100%;
	margin: 8px 0;
	/* min-height: 0px; */
	/* overflow: scroll; */
}

.feed-post-crud-controls {
	align-self: stretch;
	/* height: 100%; */
	width: 50px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-end;
	margin-right: 0px;
}

.feed-post-crud-button {
	box-sizing: border-box;
	width: 25px;
	height: 35px;
	background-color: var(--form-button-color);
	color: var(--form-text-input-color);
	padding: 10px;
	margin: 5px;
	font-family: var(--sans-serif);
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	border-radius: 8px;
	/* margin-right: 0px; */
}

.feed-post-crud-button i {
	font-size: 1em;
}

.feed-post-crud-button:hover{
	cursor: pointer;
	transition: 0.3s ease;
	transform: scale(1.01);
	box-shadow: 1px 1px 5px #05384d;
}

.feed-post-update-button {
	justify-self: center;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	background-color: var(--dark-blue);
	color: var(--form-text-input-color);
	padding: 10px;
	margin: 5px 0px;
	font-family: var(--sans-serif);
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	border: none;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	border-radius: 8px;
	margin-right: 0px;
	cursor: pointer;
}

.post-divider {
	/* align-self: center; */
	width: 100%;
	border-bottom: 0.5px solid var(--black);
	margin: 15px 0px;

}

.latest-exercise-text {
	font-style: italic;
	font-size: 0.9em;
}


/* POST - WORKOUT */
/* POST - WORKOUT */
/* POST - WORKOUT */

/* ------------------------------------------------------ */

/* WORKOUT POST - START */
/* WORKOUT POST - START */
/* WORKOUT POST - START */

.empty-workout {
	margin: 10px 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.post-workout-subtitle {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.post-workout-subtitle > div {
	margin-right: 10px;
}

/* .post-workout-rating-clippath { */
	/* clip-path: ellipse(50px 60px at 0 10% 20%); */
/* } */

.post-workout-rating {
	background-color: pink;
	transform: scale(3);
	transform-origin: bottom right;
	padding: 2px;
	border: 2px solid rgba(0,0,0,0);
	border-radius: 50%;
	/* clip-path: ellipse(50px 60px at 0 10% 20%); */
	/* clip-path: inset(10px 20px 30px 40px); */
	width: 15px;
	height: 15px;
	text-align: center;
	opacity: 90%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.workout-exercise-list-header {
	margin-bottom: 5px;
}

.workout-exercise-list-header > div {
	display: flex;
	align-items: center;
	/* justify-content: center; */
}

.feed-workout-line {
	border-bottom: 1px dotted var(--orange);
    width: 100%;
    margin: 5px auto;
}

.post-exercises-container {
	padding: 10px;
	background-color: rgb(239, 238, 238);
	display: flex;
	flex-direction: column;
}

.post-exercise-row, .workout-exercise-list-header {
	display: flex;
	flex-direction: row;
}

.post-exercise-num {
	justify-self: center;
}

.post-exercises-container > div {
	/* perspective: 400px; */
}

.post-exercises-container > div > div {
	/* transform: rotateY(45deg); */
}

.post-exercises-container > div > div:nth-child(1){
	width: 30%;
}
.post-exercises-container > div > div:nth-child(2){
	width: 22.5%;
}
.post-exercises-container > div > div:nth-child(3){
	width: 22.5%;
}
.post-exercises-container > div > div:nth-child(4){
	width: 22.5%;
}
.post-exercises-container > div > div:nth-child(5){
	width: 22.5%;
}

.post-rating-1 {
	background-color: var(--rating-1);
	color: black;
}

.post-rating-2 {
	background-color: var(--rating-2);
	color: black;
}

.post-rating-3 {
	background-color: var(--rating-3);
	color: white;
}

.post-rating-4 {
	background-color: var(--rating-4);
	color: white;
}

.post-rating-5 {
	background-color: var(--rating-5);
	color: white;
	animation: onFire 3s ease-in-out 0s infinite normal both;
}

/* WORKOUT POST - END */
/* WORKOUT POST - END */
/* WORKOUT POST - END */


@keyframes postDividerAnimation {
	0% {
		width: 0%;
		border-bottom: 0.5px solid var(--black);
	}
	100%{
		width: 100%;
		border-bottom: 0.5px solid var(--orange);

	}
}

.feed-post-editable-container:hover {

	box-shadow: 5px 10px 10px 1px #93cce8;
	transition: 0.5s;
}

.post-hover-animation {
	animation: postDividerAnimation 0.5s ease-in-out 0s 1 normal forwards;
}

.post-ellipsis {
	position: absolute;
	right: 2px;
	top: 2px;
	padding: 6px;
	border-radius: 50%;
	transition: background-color 0.5s;
}

.post-ellipsis > i {
	font-size: 1.5em;
}

#post-ellipsis-icon {
	font-size: 20px;
}

.post-ellipsis:hover {
	cursor: pointer;
	border-radius: 50%;
	
	background-color: rgb(223, 222, 219);
}

.post-ellipsis:hover > i {
	color: var(--orange);
}

.post-dropdown {
	position: absolute;
	z-index: 1;
	background-color: var(--white);
	border: 1px solid var(--light-blue);
	top: 0px;
	/* top: 1em; */
	right: 0px;
	/* right: 1em; */
	opacity: 95%;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.2);
	min-width: 35px;
	border-radius: 5px;
}

#goal-dropdown-line {
	border-bottom: 1px solid var(--black);
	width: 50%;
	margin: 0 auto;
	margin-top: 10px;
	margin-bottom: 10px;
} 

.post-dropdown li {
	display: flex;
	justify-content: center;
	padding: 4px;
	font-size: 15px;
	margin: 3px 0;
}

.post-dropdown > li:hover {
	cursor: pointer;
	color: var(--orange);
}

@keyframes onFire {
	0% {
		border-color: rgb(244, 244, 130);
		/* transform: rotateY(0deg) rotateX(0deg) scale(3) skewX(40deg); */
		/* transform-origin: center; */
		/* transform-origin: bottom right; */
	}
	20% {
		border-color: rgb(194, 169, 96);
	}
	30% {
		border-color: var(--orange);
		/* transform: rotateY(0deg) rotateX(180deg) scale(3); */
		/* transform-origin: center; */
		/* transform-origin: bottom right; */
	}
	44% {
		border-color: gray;
		/* transform: rotateY(0deg) rotateX(0deg) scale(3) skewX(40deg); */
		/* transform-origin: center; */
		/* transform-origin: bottom right; */
	}
	65% {
		border-color: var(--orange);
	}
	75% {
		border-color: red;
	}
	85% {
		border-color: red;
	}
	89% {
		border-color: red;
	}
	100% {
		border-color: rgb(244, 244, 130);
	}
}

