.follow-nav-bar-container-outer {
	position: sticky;
	top: 200px;
	left: 0px;
	/* left: -5px; */
	/* left: -5vw; */
	padding: 6px;
	/* padding-left: 5vw; */
	padding-left: calc(72px + 20px);
	/* padding-right: 5vw; */
	/* flex-grow: 2; */
	/* width: 22%; */
	height: 100%;
	box-sizing: border-box;
	/* transform-origin: top left; */
	/* transform: scale(1.3); */
	display: flex;
	width: 203px;
	min-width: 203px;
	
}

.follow-nav-bar-container-outer, .filler-div {
	/* display: none; */
	width: 22%;
	min-width: 203px;
}

.follow-nav-bar-container {
	width: 100%;
	/* height: 100%; */
	/* transition: all 0.5s; */
	padding: 15px;
	transform-origin: top left;
	transform: scale(1.15);
	background-color: white;
	border-radius: 10px;
	box-shadow: 2.5px 5px 5px 1px #c8d6dd;
	box-sizing: border-box;
	width: 203px;
	min-width: 203px;
	overflow: hidden;
}

.scrolled-follow-nav-bar {
	/* transform-origin: top left; */
	/* transform: scale(1.3); */
}

.follow-nav-bar-container *:not(.fa-solid) {
	font-family: var(--sans-serif);
}

.follow-nav-bar-container ul {
	background-color: inherit;
}

.follow-nav-bar-container li {
	/* transition: 0.5s; */
	padding: 1px;
	/* padding-bottom: 5px; */
	padding-bottom: 3px;
	padding-top: 3px;
	border-radius: 0.5px;
}

.follow-nav-bar-container a {
	font-size: 1.2em;
}

.follow-nav-bar-container a:hover {
	cursor: pointer;
	/* color: var(--orange); */
	/* background-color: var(--light-blue); */
	/* font-weight: 900; */
}

.follow-nav-bar-container a.active {
	color: var(--orange);
}

.follow-nav-active {
	color: var(--black);
}

.hover-marker {
	/* top: 34px; */
	z-index: -1;
	position: absolute;
	top: -34px;
	bottom: 2px;
	min-height: 20px;
	background-color:var(--orange);
	/* opacity: 0; */
	transform-origin: left;
	width: 1px;
	/* transform: rotateY(180deg) rotateX(90deg); */
	transform: rotateX(90deg) skewX(10deg);
	/* transition: all 0.5s; */
	/* transition: transform 1.3s, top 0.3s, left 0.3s, height 0.3s, width 0.3s, color 0.3s, box-shadow 0.3s, opacity 0.3s, transform 1.3s; */
}

.feed-links-box {
	padding: 3px 8px;
}

.feed-links-list > li > a > i {
	width: 30px;
	font-size: 0.7em;
}

/* ----------------------------------------------------- */
/* -------------------^ FEED TYPE ^--------------------- */
/* -------------------V--FILTERS--V--------------------- */
/* ----------------------------------------------------- */

.post-type-filter-container {
	position: relative;
	left: -5px;
	margin-top: 20px;
	display: flex;
	justify-content: flex-start;
	/* border-radius: 15px;/ */
	/* border: 1px solid gray; */
	/* box-sizing: border-box; */
}

.post-type-filter-container-divider {
	height: 80%;
	width: 1px;
	background-color: black;
	border-right: 1px solid black;
}

.post-type-filter-container * {
	font-size: 1.3em;
	cursor: pointer;
}

.post-type-filter-container > .post-filter-option  {
	padding-top: 11px;
	padding-bottom: 11px;
	transition: all 0.3s;
	width: 24px;
	display: flex;
	justify-content: center;
}

.post-type-filter-container > .post-filter-option:nth-child(1) {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	padding-left: 20px;
	padding-right: 15px;
}
.post-type-filter-container > .post-filter-option:nth-child(2) {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
	padding-left: 15px;
	padding-right: 20px;
}

.post-type-filter-container > .post-filter-option:hover {
	color: var(--white);
	background-color: var(--white);
	background-color: rgb(163, 178, 194);
	box-shadow: 3px 3px rgb(114, 128, 141);
}

.active-filter * {
	color: var(--orange);
}

.active-filter {
	background-color: rgb(210, 231, 255);
	box-shadow: 3px 3px rgb(114, 128, 141);
}

/* TOOLTIPS */

.post-filter-option {
	position: relative;
}

.feed-nav-tooltip {
	position: absolute;
	font-size: 0.5em;
	border-radius: 4px;
	z-index: 1;
	padding: 4px;
	background-color: black;
	color: white;
	top: 4em;
	left: 1.5em;
	opacity: 0;
	transition: all 0.1s 0.5s;
}
