.site-footer-outer-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* justify-content: center; */
	box-sizing: border-box;
	/* margin-top: auto; */
	
	/* position: absolute; */
	/* bottom: 0; */

	/* padding: 20px; */
	/* height: 80px; */
	/* height: 100%; */
	background-color: var(--white);
	color: var(--black);
	color: rgb(94, 94, 94);
	/* padding: 10px 0px; */
	/* z-index: 2; */
}

.footer-offset {
	height: 80px;
}

.footer-divider {
	width: 95%;
	border-top: 0.5px solid var(--black);
	margin-top: 20px;
}

.team-links-text {
	font-family: var(--sans-serif);
}

.site-footer-container {
	/* background-color: red; */
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	box-sizing: border-box;
}

.team-links {
	margin-top: 20px;
	display: inline-flex;
	gap: 10px;
}

.team-links-light * {
	color: var(--soft-white);
}

.team-links-dark * {
	color: var(--dark-blue);
}

.team-links *:hover {
	cursor: pointer;
}

.copyright {
	padding-top: 20px;
	padding-bottom: 30px;
	font-family: var(--sans-serif);
	font-size: 0.8em;
}

