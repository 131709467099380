.main-page-wrapper {
	width: 100%;
	/* height: 100%; */
	/* min-height: calc(100vh - 210px); */
	/* padding-top: 5px; */
	
	box-sizing: border-box;
	color: var(--dark-blue);
	opacity: 0;
	/* margin-top: 150px; */
	position: relative;
}

.main-fade {
	animation: mainFadeAnimation 0.7s ease 1s 1 normal forwards;
	padding-left: 80px;
	padding-right: 80px;
	/* opacity: 1; */
	/* transition: opacity 0.7s ease 0.5s; */
}

@keyframes mainFadeAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.logged-out {
	opacity:1;
}